import { useEffect, useMemo } from "react";
import { useFieldArray } from "react-hook-form";
import { Grid, Box, Typography } from "@mui/material";
import { BasicTextField, BasicSelect } from "@datwyler/shared-components";
import { CONFIG_DETAILS_SECTION } from "./config";
import { getConfigDetailValues } from "../../utils/index";

const getProtocolDetailFields = (protocolType) => {
  return CONFIG_DETAILS_SECTION.find(
    (protocol) => protocol.type === protocolType
  );
};

const Details = ({
  form,
  colors,
  intl,
  configData = { protocol: "snmpv2c" },
}: any) => {
  const { control } = form;
  useFieldArray({
    control,
    name: "details",
  });

  useEffect(() => {
    const defaults = getConfigDetailValues(configData);
    form.resetField("details", { defaultValue: [{ ...defaults }] });
  }, [form, configData]);

  const { fields: inputFields } = getProtocolDetailFields(configData.protocol);

  const createFields = () => {
    return Object.keys(inputFields).map((fieldKey, index) => {
      const fieldDetails = inputFields[fieldKey];
      switch (fieldDetails.type) {
        case "text":
          return (
            <Grid item xs={fieldDetails.xs} key={index}>
              <BasicTextField
                form={form}
                label={intl.formatMessage({ id: fieldDetails.label })}
                type={"text"}
                formItemLabel={`details.0.${fieldDetails.name}`}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          );
        case "number":
          return (
            <Grid item xs={fieldDetails.xs} key={index}>
              <BasicTextField
                form={form}
                label={intl.formatMessage({ id: fieldDetails.label })}
                type={"number"}
                formItemLabel={`details.0.${fieldDetails.name}`}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          );
        case "select":
          return (
            <Grid item xs={fieldDetails.xs} key={index}>
              <BasicSelect
                form={form}
                menuItems={(fieldDetails.options || []).map((option) => ({
                  priority: option.priority,
                  label: intl.formatMessage({ id: option.label }),
                  value: option.value,
                }))}
                formItemLabel={`details.0.${fieldDetails.name}`}
                label={intl.formatMessage({ id: fieldDetails.label })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          );
      }
    });
  };

  return (
    <Box sx={{ padding: "16px 16px 32px 16px" }}>
      <Typography
        sx={{ margin: "24px 0px", fontWeight: 550, fontSize: "16px" }}
      >
        {intl.formatMessage({ id: "gateway_details" })}
      </Typography>
      <Grid container spacing={2}>
        {createFields()}
      </Grid>
    </Box>
  );
};

export default Details;
