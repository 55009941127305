import { useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Stack,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Divider,
  colors,
} from "@mui/material";
import {
  CloseIcon24p,
  BasicButton,
  BasicSelect,
  BasicTextField,
} from "@datwyler/shared-components";

const defaultValues = {
  name: "",
  protocol: null,
};

export const SelectDisplayProps = {
  paddingTop: "13px",
  paddingBottom: "12px",
  paddingLeft: "16px",
  height: "19px",
};

export const inputLabelSx = {
  fontFamily: "NotoSans-Regular",
  fontSize: "14px",
  letterSpacing: "0.01px",
  lineHeight: "24px",
};

export const menuPaperSx = { width: "376px" };

const Connection = ({
  colors,
  intl,
  protocols = [],
  open,
  onClose,
  gatewayId,
  tenantId,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const rules = useMemo(
    () => ({
      required: {
        type: "required",
        value: true,
        message: intl.formatMessage({ id: "validation_required" }),
      },
    }),
    []
  );
  const form = useForm({
    defaultValues: { ...defaultValues },
  });

  const handleSubmit = async (e) => {
    let isValid = await form.trigger();
    if (isValid) {
      navigate(`/gateway/connection`, {
        state: {
          ...location.state,
          configData: form.getValues(),
          type: "create",
          gatewayId,
        },
      });
    }
  };

  const protocolOptions = useMemo(() => {
    return protocols.map((protocol, index) => ({
      priority: index,
      label: protocol,
      value: protocol,
    }));
  }, [protocols]);

  return (
    <Dialog
      open={open}
      maxWidth={"sm"}
      sx={{
        "&& .MuiDialog-container": {
          alignItems: "unset",
          marginTop: "5vh",
          height: "unset",
        },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "16px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          Add Connection
        </Typography>
        <IconButton
          onClick={() => onClose(false)}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ borderColor: colors.dividerColor }} />
      <DialogContent>
        <Stack spacing={2} sx={{ width: 376 }}>
          <BasicSelect
            form={form}
            menuItems={protocolOptions}
            formItemLabel={"protocol"}
            label={"Protocol"}
            rules={rules}
          />
          <BasicTextField
            form={form}
            label={"Connection Name"}
            type={"text"}
            formItemLabel={"name"}
            rules={rules}
          />
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "flex-end", padding: "0 24px 16px 0" }}
      >
        <BasicButton
          size="large"
          variant={"outlined"}
          onClick={() => onClose(false)}
          sx={{ width: "100px", paddingTop: "8px", paddingBottom: "8px" }}
        >
          Cancel
        </BasicButton>
        <BasicButton
          variant={"contained"}
          onClick={handleSubmit}
          sx={{ width: "100px", paddingTop: "8px", paddingBottom: "8px" }}
        >
          Next
        </BasicButton>
      </DialogActions>
    </Dialog>
  );
};

export default Connection;
