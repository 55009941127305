import { useContext } from "react";
import {
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import {
  CloseIcon24p,
  BasicButton,
  DarkModeContext,
} from "@datwyler/shared-components";

const ConfirmDialog = (props) => {
  const {
    isOpen = false,
    title,
    bodyTextContent,
    confirmButtonText,
    handleClose,
    handleConfirm,
    cancelButtonText,
    confirmButtonStyle,
  } = props;
  const { colors }: any = useContext(DarkModeContext);

  return (
    <Dialog
      maxWidth={"sm"}
      open={isOpen}
      onClose={() => handleClose(false)}
      PaperProps={{
        sx: { borderRadius: "8px", backgroundColor: colors.cardBg },
      }}
    >
      <DialogTitle sx={{ paddingTop: "26px", paddingBottom: "16px" }}>
        <Typography
          sx={{
            fontFamily: "NotoSans-SemiBold",
            fontSize: 16,
            color: colors.subTitle,
          }}
        >
          {title}
        </Typography>
        <IconButton
          onClick={() => handleClose(false)}
          sx={{ position: "absolute", right: 16, top: 16 }}
        >
          <CloseIcon24p />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ fontSize: 14, paddingTop: 1, width: 376 }}>
          <Typography
            sx={{
              fontFamily: "NotoSans-Regular",
              fontSize: 14,
              color: colors.fontTitle,
            }}
          >
            {bodyTextContent}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "flex-start", paddingBottom: "24px" }}
      >
        <Box sx={{ marginLeft: "16px" }}>
          <BasicButton onClick={() => handleClose(false)} variant={"outlined"}>
            {cancelButtonText}
          </BasicButton>
        </Box>
        <Box sx={{ position: "absolute", right: "24px" }}>
          <BasicButton
            onClick={handleConfirm}
            variant={"contained"}
            sx={confirmButtonStyle}
          >
            {confirmButtonText}
          </BasicButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
