import {
  Button,
  CardContent,
  CardActions,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import { BasicCard, BasicChip, BasicButton } from "@datwyler/shared-components";
import Status from "../Status";
const DeviceCard = ({
  intl,
  colors,
  deviceConfigs,
  handleSettingClick,
  handleDeleteClick,
}) => {
  return (
    <>
      <BasicCard sx={{ padding: "8px" }}>
        <CardContent sx={{ paddingBottom: "0" }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <BasicChip
              label={deviceConfigs?.group}
              backgroundColor={colors.teal50}
              labelSx={{ color: colors.teal600 }}
            />
            {/* <Status
              status={deviceConfigs?.status}
              colors={colors}
              intl={intl}
            /> */}
          </Box>
          <Box sx={{ padding: "16px 8px" }}>
            <Typography sx={{ fontWeight: 700, fontSize: "16px" }}>
              {deviceConfigs?.name}
            </Typography>
            <Stack spacing={1} sx={{ marginTop: "8px" }}>
              {deviceConfigs?.details?.map((detail, index) => (
                <Box key={index}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                    {detail.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: colors.blueGray400,
                    }}
                  >
                    {detail.value}
                  </Typography>
                </Box>
              ))}
            </Stack>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "right", paddingTop: "0" }}>
          <BasicButton
            variant="outlined"
            onClick={() => handleDeleteClick(deviceConfigs?.raw)}
          >
            <Typography>
              {intl.formatMessage({ id: "gateway_delete" })}
            </Typography>
          </BasicButton>
          <BasicButton
            variant="contained"
            onClick={() => handleSettingClick(deviceConfigs?.raw)}
          >
            <Typography>
              {intl.formatMessage({ id: "gateway_setting" })}
            </Typography>
          </BasicButton>
        </CardActions>
      </BasicCard>
    </>
  );
};

export default DeviceCard;
