const TYPE_OPTIONS = [
  {
    priority: 0,
    label: "gateway_int",
    value: "INTEGER",
  },
  {
    priority: 1,
    label: "gateway_double",
    value: "DOUBLE",
  },
  {
    priority: 2,
    label: "gateway_string",
    value: "STRING",
  },
  {
    priority: 3,
    label: "gateway_boolean",
    value: "BOOLEAN",
  },
];

const BOOLEAN_OPTIONS = [
  {
    priority: 0,
    label: "gateway_true",
    value: "true",
  },
  {
    priority: 1,
    label: "gateway_false",
    value: "false",
  },
];

const PUBLIC_PRIVATE_OPTIONS = [
  {
    priority: 0,
    label: "gateway_public",
    value: "public",
  },
  {
    priority: 1,
    label: "gateway_private",
    value: "private",
  },
];

const ALARM_LEVEL_OPTIONS = [
  {
    priority: 0,
    label: "gateway_alarm_info",
    value: "INFORMATION",
  },
  {
    priority: 1,
    label: "gateway_alarm_warn",
    value: "WARNING",
  },
  {
    priority: 2,
    label: "gateway_alarm_crictical",
    value: "CRITICAL",
  },
];

const ALARM_STATUS_OPTIONS = [
  {
    priority: 0,
    label: "gateway_alarm_activated",
    value: "ACTIVE",
  },
  {
    priority: 1,
    label: "gateway_alarm_deactivated",
    value: "DEACTIVATED",
  },
];

const OPERATOR = [
  {
    priority: 0,
    label: "gateway_operator_eq",
    value: "EQ",
  },
  {
    priority: 1,
    label: "gateway_operator_ne",
    value: "NE",
  },
  {
    priority: 2,
    label: "gateway_operator_gt",
    value: "GT",
  },
  {
    priority: 3,
    label: "gateway_operator_ge",
    value: "GE",
  },
  {
    priority: 4,
    label: "gateway_operator_lt",
    value: "LT",
  },
  {
    priority: 5,
    label: "gateway_operator_le",
    value: "LE",
  },
];

const MODBUS_TYPE = [
  "string",
  "ascii",
  "int8",
  "uint8",
  "int16",
  "uint16",
  "int32",
  "uint32",
  "int64",
  "uint64",
  "float32",
];

export const SENSOR_TYPE = [
  "humidity",
  "temperature",
  "pressure",
  "open-close",
  "pressure_dif",
  "battery",
];

const createOptions = ({
  values,
  formatLabel = (label) => label,
  formatValue = (value) => value,
}) =>
  values.map((value, index) => ({
    priority: index,
    value: formatValue(value),
    label: formatLabel(value),
  }));

const ALARMS_TAB_FIELDS = {
  name: {
    name: "name",
    label: "gateway_alarms_name_tab",
    type: "text",
    defaultValue: "",
  },
  description: {
    name: "description",
    label: "gateway_alarms_description_tab",
    type: "text",
    defaultValue: "",
  },
  email: {
    name: "email",
    label: "gateway_alarms_email",
    type: "text",
    defaultValue: "",
  },
  level: {
    name: "level",
    label: "gateway_alarms_level",
    type: "select",
    options: ALARM_LEVEL_OPTIONS,
  },
  status: {
    name: "status",
    label: "gateway_alarms_status",
    type: "select",
    options: ALARM_STATUS_OPTIONS,
  },
  attribute_name: {
    name: "attribute_name",
    label: "gateway_alarms_attribute_name",
    type: "select",
    options: [],
    defaultValue: "",
  },
  type: {
    name: "type",
    label: "gateway_alarms_operator_name",
    type: "select",
    options: OPERATOR,
    defaultValue: "",
  },
  value: {
    name: "value",
    label: "gateway_alarms_operator_value",
    type: "text",
    defaultValue: "",
  },
};

export const CONFIG_TABS = [
  {
    type: "snmpv2c",
    dynamics: {
      key: {
        name: "key",
        label: "gateway_dynamic_key",
        type: "text",
        defaultValue: "",
      },
      oid: {
        name: "oid",
        label: "gateway_dynamic_oid",
        type: "text",
        defaultValue: "",
      },
      unit: {
        name: "unit",
        label: "gateway_dynamic_unit",
        type: "text",
        defaultValue: "",
      },
      multiplier: {
        name: "multiplier",
        label: "gateway_dynamic_multiplier",
        type: "number",
        defaultValue: "",
      },
      function: {
        name: "function",
        label: "gateway_dynamic_function",
        type: "text",
        defaultValue: "",
      },
      type: {
        name: "type",
        label: "gateway_dynamic_type",
        type: "select",
        options: TYPE_OPTIONS,
        defaultValue: "",
      },
      writable: {
        name: "writable",
        label: "gateway_dynamic_writable",
        type: "select",
        options: BOOLEAN_OPTIONS,
        defaultValue: "",
      },
      is_logged: {
        name: "is_logged",
        label: "gateway_dynamic_logged",
        type: "select",
        options: BOOLEAN_OPTIONS,
        defaultValue: "",
      },
    },
    statics: {
      key: {
        name: "key",
        label: "gateway_statics_key",
        type: "text",
        defaultValue: "",
      },
      oid: {
        name: "oid",
        label: "gateway_statics_oid",
        type: "text",
        defaultValue: "",
      },
      unit: {
        name: "unit",
        label: "gateway_statics_unit",
        type: "text",
        defaultValue: "",
      },
      multiplier: {
        name: "multiplier",
        label: "gateway_statics_multiplier",
        type: "number",
        defaultValue: "",
      },
      function: {
        name: "function",
        label: "gateway_statics_function",
        type: "text",
        defaultValue: "",
      },
      type: {
        name: "type",
        label: "gateway_statics_type",
        type: "select",
        options: TYPE_OPTIONS,
        defaultValue: "",
      },
      writable: {
        name: "writable",
        label: "gateway_statics_writable",
        type: "select",
        options: BOOLEAN_OPTIONS,
        defaultValue: "",
      },
      is_logged: {
        name: "is_logged",
        label: "gateway_statics_logged",
        type: "select",
        options: BOOLEAN_OPTIONS,
        defaultValue: "",
      },
    },
    trap: {
      oid: {
        name: "oid",
        label: "gateway_trap_oid",
        type: "text",
        defaultValue: "",
      },
      key: {
        name: "key",
        label: "gateway_trap_key",
        type: "text",
        defaultValue: "",
      },
    },
    alarms: ALARMS_TAB_FIELDS,
  },
  {
    type: "modbustcp",
    dynamics: {
      key: {
        name: "key",
        label: "gateway_dynamic_key",
        type: "text",
        defaultValue: "",
      },
      fc: {
        name: "fc",
        label: "gateway_dynamic_fc",
        type: "number",
        defaultValue: "",
      },
      address: {
        name: "address",
        label: "gateway_dynamic_address",
        type: "text",
        defaultValue: "",
      },
      length: {
        name: "length",
        label: "gateway_dynamic_length",
        type: "number",
        defaultValue: "",
      },
      modbus_type: {
        name: "modbus_type",
        label: "gateway_dynamic_modbus_type",
        type: "select",
        options: createOptions({
          values: MODBUS_TYPE,
          formatLabel: (label) => label.toUpperCase(),
        }),
        defaultValue: "",
      },
      unit: {
        name: "unit",
        label: "gateway_dynamic_unit",
        type: "text",
        defaultValue: "",
      },
      multiplier: {
        name: "multiplier",
        label: "gateway_dynamic_multiplier",
        type: "number",
        defaultValue: "",
      },
      function: {
        name: "function",
        label: "gateway_dynamic_function",
        type: "text",
        defaultValue: "",
      },
      type: {
        name: "type",
        label: "gateway_dynamic_type",
        type: "select",
        options: TYPE_OPTIONS,
        defaultValue: "",
      },
      writable: {
        name: "writable",
        label: "gateway_dynamic_writable",
        type: "select",
        options: BOOLEAN_OPTIONS,
        defaultValue: "",
      },
      is_logged: {
        name: "is_logged",
        label: "gateway_dynamic_logged",
        type: "select",
        options: BOOLEAN_OPTIONS,
        defaultValue: "",
      },
    },
    statics: {
      key: {
        name: "key",
        label: "gateway_statics_key",
        type: "text",
        defaultValue: "",
      },
      fc: {
        name: "fc",
        label: "gateway_statics_fc",
        type: "number",
        defaultValue: "",
      },
      address: {
        name: "address",
        label: "gateway_statics_address",
        type: "text",
        defaultValue: "",
      },
      length: {
        name: "length",
        label: "gateway_statics_length",
        type: "number",
        defaultValue: "",
      },
      modbus_type: {
        name: "modbus_type",
        label: "gateway_statics_modbus_type",
        type: "select",
        options: createOptions({
          values: MODBUS_TYPE,
          formatLabel: (label) => label.toUpperCase(),
        }),
        defaultValue: "",
      },
      unit: {
        name: "unit",
        label: "gateway_statics_unit",
        type: "text",
        defaultValue: "",
      },
      multiplier: {
        name: "multiplier",
        label: "gateway_statics_multiplier",
        type: "number",
        defaultValue: "",
      },
      function: {
        name: "function",
        label: "gateway_statics_function",
        type: "text",
        defaultValue: "",
      },
      type: {
        name: "type",
        label: "gateway_statics_type",
        type: "select",
        options: TYPE_OPTIONS,
        defaultValue: "",
      },
      writable: {
        name: "writable",
        label: "gateway_statics_writable",
        type: "select",
        options: BOOLEAN_OPTIONS,
        defaultValue: "",
      },
      is_logged: {
        name: "is_logged",
        label: "gateway_statics_logged",
        type: "select",
        options: BOOLEAN_OPTIONS,
        defaultValue: "",
      },
    },
    trap: {},
    alarms: ALARMS_TAB_FIELDS,
  },
  {
    type: "efento",
    dynamics: {
      key: {
        name: "key",
        label: "gateway_dynamic_key",
        type: "text",
        defaultValue: "",
      },
      channel: {
        name: "channel",
        label: "gateway_dynamic_channel",
        type: "number",
        defaultValue: "",
      },
      sensor_type: {
        name: "sensor_type",
        label: "gateway_dynamic_sensor_type",
        type: "select",
        options: createOptions({
          values: SENSOR_TYPE,
          formatLabel: (label) => label.toUpperCase(),
        }),
        defaultValue: "",
      },
      unit: {
        name: "unit",
        label: "gateway_dynamic_unit",
        type: "select",
        defaultValue: "",
      },
      multiplier: {
        name: "multiplier",
        label: "gateway_dynamic_multiplier",
        type: "number",
        defaultValue: "",
      },
      function: {
        name: "function",
        label: "gateway_dynamic_function",
        type: "text",
        defaultValue: "",
      },
      type: {
        name: "type",
        label: "gateway_dynamic_type",
        type: "select",
        options: TYPE_OPTIONS,
        defaultValue: "",
      },
      is_logged: {
        name: "is_logged",
        label: "gateway_dynamic_logged",
        type: "select",
        defaultValue: "",
      },
    },
    statics: {},
    trap: {},
    alarms: ALARMS_TAB_FIELDS,
  },
];

export const CONFIG_DETAILS_SECTION = [
  {
    type: "snmpv2c",
    fields: {
      name: {
        name: "name",
        label: "gateway_details_name",
        type: "text",
        xs: 12,
      },
      deviceId: {
        name: "deviceId",
        label: "gateway_details_deviceId",
        type: "text",
        xs: 12,
      },
      group: {
        name: "group",
        label: "gateway_details_group",
        type: "text",
        xs: 12,
      },
      ip_address: {
        name: "ip_address",
        label: "gateway_details_ip_address",
        type: "text",
        xs: 8,
      },
      port: {
        name: "port",
        label: "gateway_details_port",
        type: "number",
        xs: 4,
      },
      read_community: {
        name: "read_community",
        label: "gateway_details_read_community",
        type: "select",
        options: PUBLIC_PRIVATE_OPTIONS,
        xs: 12,
      },
      write_community: {
        name: "write_community",
        label: "gateway_details_write_community",
        type: "select",
        options: PUBLIC_PRIVATE_OPTIONS,
        xs: 12,
      },
      activated: {
        name: "activated",
        label: "gateway_details_activated",
        type: "select",
        options: BOOLEAN_OPTIONS,
        xs: 6,
      },
      interval: {
        name: "interval",
        label: "gateway_details_interval",
        type: "number",
        xs: 6,
      },
    },
  },
  {
    type: "modbustcp",
    fields: {
      name: {
        name: "name",
        label: "gateway_details_name",
        type: "text",
        xs: 12,
      },
      deviceId: {
        name: "deviceId",
        label: "gateway_details_deviceId",
        type: "text",
        xs: 12,
      },
      group: {
        name: "group",
        label: "gateway_details_group",
        type: "text",
        xs: 12,
      },
      ip_address: {
        name: "ip_address",
        label: "gateway_details_ip_address",
        type: "text",
        xs: 8,
      },
      port: {
        name: "port",
        label: "gateway_details_port",
        type: "number",
        xs: 4,
      },
      unit_id: {
        name: "unit_id",
        label: "gateway_details_port_unit_id",
        type: "number",
        xs: 12,
      },
      activated: {
        name: "activated",
        label: "gateway_details_activated",
        type: "select",
        options: BOOLEAN_OPTIONS,
        xs: 6,
      },
      interval: {
        name: "interval",
        label: "gateway_details_interval",
        type: "number",
        xs: 6,
      },
    },
  },
  {
    type: "efento",
    fields: {
      name: {
        name: "name",
        label: "gateway_details_name",
        type: "text",
        xs: 12,
      },
      deviceId: {
        name: "deviceId",
        label: "gateway_details_deviceId",
        type: "text",
        xs: 12,
      },
      group: {
        name: "group",
        label: "gateway_details_group",
        type: "text",
        xs: 12,
      },
      serial_number: {
        name: "serial_number",
        label: "gateway_details_serial_number",
        type: "text",
        xs: 12,
      },
      activated: {
        name: "activated",
        label: "gateway_details_activated",
        type: "select",
        options: BOOLEAN_OPTIONS,
        xs: 12,
      },
    },
  },
  {},
];
