import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { MoreButton, BasicBreadcrumbs } from "@datwyler/shared-components";
import { HeaderType } from "../utils/types";

const Header = ({ device, colors, activePage = "Gateway" }: HeaderType) => {
  const name = device?.name;
  const { fontTitle } = colors;
  return (
    <Box>
      <BasicBreadcrumbs activePage={activePage} device={device} />
      <Box sx={{ display: "inline-flex", marginTop: "16px" }}>
        <Typography
          sx={{
            color: fontTitle,
            fontFamily: "NotoSans-SemiBold",
            fontSize: "24px",
            fontWeight: "600",
            letterSpacing: "0",
            lineHeight: "34px",
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
