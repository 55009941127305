import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import {
  CircleIcon,
  BasicDataTable,
  getLocationFromSiteId,
} from "@datwyler/shared-components";
import { DataTableType } from "../utils/types";
import { getTelemetry } from "../utils";
import { ENUM_ON } from "../utils/constants";

const headers = [
  "gateway_table_name",
  "gateway_table_type",
  "gateway_table_id",
  "gateway_table_location",
  "gateway_table_site",
  "gateway_table_status",
];
const columnConfigs = {
  gateway_table_name: {
    colspan: 1,
    isCentralized: false,
  },
  gateway_table_type: {
    colspan: 2,
    isCentralized: true,
  },
  gateway_table_id: {
    colspan: 3,
    isCentralized: true,
  },
  gateway_table_location: {
    colspan: 2,
    isCentralized: true,
  },
  gateway_table_site: {
    colspan: 2,
    isCentralized: true,
  },
  gateway_table_status: {
    colspan: 2,
    isCentralized: true,
  },
};

const DataTable = (props: DataTableType) => {
  const { handleRowClick, devices, locations = [], colors, intl } = props;

  const fontRegular = useMemo(
    () => ({
      color: colors.dataTableFont,
      fontFamily: "NotoSans-Regular",
      fontSize: "14px",
      letterSpacing: "0.02px",
      lineHeight: "20px",
      marginTop: "4px",
    }),
    [colors.dataTableFont]
  );

  const renderDevice = (device) => {
    return (
      <Box>
        <Typography sx={fontRegular}>{device.name}</Typography>
      </Box>
    );
  };

  const renderType = (device) => {
    return (
      <Box>
        <Typography sx={fontRegular}>
          {intl.formatMessage({ id: device.type })}
        </Typography>
      </Box>
    );
  };

  const renderDeviceId = (device) => {
    return (
      <Box>
        <Typography sx={fontRegular} id={device.id}>
          {device.id}
        </Typography>
      </Box>
    );
  };

  const renderStatus = (device) => {
    const status = getTelemetry(device?.telemetry, "status");
    return (
      <Box>
        <CircleIcon
          sx={{
            color:
              status?.value === ENUM_ON ? colors.green500 : colors.blueGray300,
            width: "10px",
            height: "10px",
          }}
        />
      </Box>
    );
  };

  const renderLocation = (device) => {
    const location = getLocationFromSiteId(locations, device?.site?.id);
    return (
      <Box>
        <Typography sx={fontRegular}>{location?.name}</Typography>
      </Box>
    );
  };

  const renderSite = (device) => {
    return (
      <Box>
        <Typography sx={fontRegular}>{device?.site?.name}</Typography>
      </Box>
    );
  };

  const formattedData = [];
  const dataCopy = JSON.parse(JSON.stringify(devices));
  dataCopy.forEach((device) => {
    formattedData.push({
      gateway_table_name: renderDevice(device),
      gateway_table_id: renderDeviceId(device),
      gateway_table_type: renderType(device),
      gateway_table_status: renderStatus(device),
      gateway_table_location: renderLocation(device),
      gateway_table_site: renderSite(device),
      additionalInfo: device,
    });
  });

  return (
    <Box sx={{ marginTop: "25px" }}>
      <BasicDataTable
        headers={headers}
        data={formattedData}
        isHideCheckbox={true}
        columnConfigs={columnConfigs}
        handleClickRow={handleRowClick}
        isHideActions={true}
      />
    </Box>
  );
};

export default DataTable;
