import { Box, Grid, CircularProgress } from "@mui/material";
import Header from "./Header";
import DeviceCard from "./Card";
import Connection from "./Connection";
import { PROTOCOLS } from "../../utils/constants";
const Devices = ({
  intl,
  colors,
  isAddConnectionOpen,
  setIsAddConnectionOpen,
  deviceConfigDataLoading,
  deviceConfigData,
  handleSettingClick,
  handleDeleteClick,
  handleReinitialize,
  gatewayId,
  tenantId,
}) => {
  return (
    <Box>
      <Header
        onAddConnection={setIsAddConnectionOpen}
        handleReinitialize={handleReinitialize}
      />
      {deviceConfigDataLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "250px",
            color: colors.datwylerTeal,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          <Grid container spacing={2} mt="8px" mb="32px">
            {deviceConfigData.map((deviceConfigs, index) => (
              <Grid key={index} item xl={4} lg={4} md={6} xs={12}>
                <DeviceCard
                  intl={intl}
                  key={index}
                  colors={colors}
                  deviceConfigs={deviceConfigs}
                  handleSettingClick={handleSettingClick}
                  handleDeleteClick={handleDeleteClick}
                />
              </Grid>
            ))}
          </Grid>
          <Connection
            colors={colors}
            intl={intl}
            protocols={PROTOCOLS}
            open={isAddConnectionOpen}
            onClose={setIsAddConnectionOpen}
            gatewayId={gatewayId}
            tenantId={tenantId}
          />
        </>
      )}
    </Box>
  );
};

export default Devices;
