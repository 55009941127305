import { Grid } from "@mui/material";
import {
  PowerSettingsNewIcon,
  SimCardIcon,
  ConnectionIcon,
  FirmwareUploadIcon,
} from "@datwyler/shared-components";
import StatusCard from "./StatusCard";
import { getTelemetryData } from "../utils";
import { CardsType } from "../utils/types";

const Cards = ({ device, intl, colors }: CardsType) => {
  const telemetry = device?.telemetry;
  const cardsData = getTelemetryData(
    telemetry,
    {
      status: {
        icon: PowerSettingsNewIcon as React.FC,
        width: 2,
        order: 1,
      },
      modem_operator: {
        icon: SimCardIcon as React.FC,
        width: 3,
        order: 3,
      },
      last_connection: {
        icon: ConnectionIcon as React.FC,
        width: 4,
        order: 2,
      },
      device_firmware_version: {
        icon: FirmwareUploadIcon as React.FC,
        width: 3,
        order: 4,
      },
    },
    intl,
    colors
  );

  return (
    <Grid container spacing={1} sx={{ marginTop: "24px" }}>
      {cardsData.map((card, index) => {
        return (
          <Grid key={index} item xs={12} lg={card.width}>
            <StatusCard {...card} intl={intl} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Cards;
