import { useContext, useMemo, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Divider, CircularProgress } from "@mui/material";
import {
  BasicBreadcrumbs,
  BasicScreenHeader,
  DarkModeContext,
  TenantIdContext,
  BasicPagination,
} from "@datwyler/shared-components";
import DataTable from "../components/DataTable";
import Filters from "../components/Filters";
import SortBy from "../components/SortBy";
import { ColorsType } from "../utils/types";
import useGatewayList from "../hooks/useGatewayList";
import { getSortOptions, getSiteOptions } from "../utils/index";
const Gateway = () => {
  const intl = useIntl();
  const { tenantId }: any = useContext(TenantIdContext);
  const { colors }: { colors: ColorsType } = useContext(DarkModeContext);
  const sortOptions = useMemo(() => getSortOptions(intl), [intl]);
  const navigate = useNavigate();
  const location = useLocation();

  const {
    devices,
    devicesLoading,
    locations,
    rowsPerPage,
    setRowsPerPage,
    page,
    setPage,
    totalRows,
    filters,
    setFilters,
    sort,
    setSort,
  } = useGatewayList(tenantId);

  const siteOptions = useMemo(() => getSiteOptions(locations), [locations]);
  const getLowerComponent = useCallback(() => {
    return (
      <>
        <Divider sx={{ borderColor: colors.dividerColor }} />
        <Box
          sx={{
            display: "inline-flex",
            verticalAlign: "top",
            paddingTop: "16px",
            marginLeft: "24px",
          }}
        >
          <Filters
            siteOptions={siteOptions}
            filters={filters}
            setFilters={setFilters}
          />
          <SortBy options={sortOptions} setOrder={setSort} order={sort} />
        </Box>
      </>
    );
  }, [
    filters,
    setFilters,
    sortOptions,
    colors.dividerColor,
    siteOptions,
    sort,
    setSort,
  ]);

  const handleRowClick = ({ id }: { id: string }) => {
    navigate(`/gateway/manage-gateway`, {
      state: { ...location.state, gatewayId: id },
    });
  };

  return (
    <>
      <Box
        sx={{ paddingTop: "76px", paddingLeft: "24px", paddingRight: "24px" }}
      >
        <BasicBreadcrumbs activePage={"Gateway"} />
        <BasicScreenHeader
          title={intl.formatMessage({ id: "gateway_header_title" })}
          LowerComponent={getLowerComponent()}
        />
        {devicesLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "250px",
              color: colors.datwylerTeal,
            }}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <>
            <DataTable
              handleRowClick={handleRowClick}
              devices={devices?.devices || []}
              colors={colors}
              intl={intl}
              locations={locations}
            />
            <BasicPagination
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
              totalRows={totalRows}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default Gateway;
