import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import GatewayDetails from "./containers/manage";
import Gateway from "./containers/index";
import Connection from "./containers/connection";
import { TenantIdProvider } from "@datwyler/shared-components";
const Router = () => {
  const [tenantId, setTenantId] = useState(null);
  const location = useLocation();
  useEffect(() => {
    if (location?.state?.tenantId) setTenantId(location.state.tenantId);
  }, [location]);
  return (
    <TenantIdProvider tenantId={tenantId}>
      <Routes>
        <Route path="gateway" element={<Gateway />} />
        <Route path="/gateway/manage-gateway" element={<GatewayDetails />} />
        <Route path="/gateway/connection" element={<Connection />} />
      </Routes>
    </TenantIdProvider>
  );
};

export default Router;
