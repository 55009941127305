import { useCallback, useMemo, useContext, useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, useFieldArray } from "react-hook-form";
import {
  BasicBreadcrumbs,
  DarkModeContext,
  useSnackbar,
} from "@datwyler/shared-components";
import { Box, Grid } from "@mui/material";
import MenuTabs from "../components/Tabs";
import Header from "../components/Connection/Header";
import Details from "../components/Connection/Details";
import Dynamics from "../components/Connection/Dynamics";
import Statics from "../components/Connection/Statics";
import Trap from "../components/Connection/Trap";
import Alarm from "../components/Connection/Alarm";
import { ColorsType } from "../utils/types";
import { CONFIG_TABS } from "../components/Connection/config";
import useGatewayConfiguration from "../hooks/useGatewayConfiguration";
const Connection = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [activeTab, setActiveTab] = useState("gateway_dynamics");
  const { colors }: { colors: ColorsType } = useContext(DarkModeContext);
  const {
    updateGatewayDevice,
    updateGatewayDeviceData,
    addGatewayDevice,
    addGatewayDeviceData,
  } = useGatewayConfiguration(state?.tenantId, state?.gatewayId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const configData = state.configData || {};

  const form = useForm({
    shouldUnregister: false,
    defaultValues: {
      dynamics: [],
      statics: [],
      alarms: [],
      trap: [],
      details: [],
    },
  });
  const { control } = form;
  const {
    fields: trapFieldArray,
    append: appendTrapField,
    replace: replaceTrapField,
    remove: removeTrapField,
  } = useFieldArray({
    control,
    name: "trap",
  });

  const {
    fields: dynamicsFieldArray,
    append: appendDynamicsField,
    replace: replaceDynamicsField,
    remove: removeDynamicsField,
  } = useFieldArray({
    control,
    name: "dynamics",
  });

  const {
    fields: staticsFieldArray,
    append: appendStaticsField,
    remove: replaceStaticsField,
    replace: removeStaticsField,
  } = useFieldArray({
    control,
    name: "statics",
  });

  const {
    fields: alarmsFieldArray,
    append: appendAlarmsField,
    replace: replaceAlarmsField,
    remove: removeAlarmsField,
  } = useFieldArray({
    control,
    name: "alarms",
  });

  const { trap, dynamics, statics, alarms } = useMemo(() => {
    return CONFIG_TABS.find(
      (protocol) => protocol.type === configData?.protocol
    );
  }, [configData]);

  const tabConfigs = useMemo(
    () => ({
      trap,
      dynamics,
      statics,
      alarms,
    }),
    [trap, dynamics, statics, alarms]
  );

  useEffect(() => {
    if (addGatewayDeviceData || updateGatewayDeviceData) {
      enqueueSnackbar(intl.formatMessage({ id: "gateway_success_message" }), {
        variant: "success",
      });
    }
  }, [enqueueSnackbar, addGatewayDeviceData, updateGatewayDeviceData, intl]);

  const transformFieldValues = useCallback(
    (datapointKey, datapointValue) => {
      const defaultValue = [];
      datapointValue.forEach((datapoint, index) => {
        defaultValue[index] = {};
        Object.keys(tabConfigs[datapointKey]).forEach((key) => {
          if (
            datapointKey === "alarms" &&
            (key === "attribute_name" || key === "type" || key === "value")
          ) {
            defaultValue[index][key] = datapoint?.properties
              ? datapoint?.properties[key]
              : "";
          } else {
            defaultValue[index][key] = datapoint[key];
          }
        });
      });
      return defaultValue;
    },
    [tabConfigs]
  );

  useEffect(() => {
    const datapoints = {
      ...(configData?.datapoints || {}),
      alarms: configData?.alarms || [],
    };
    Object.keys(datapoints).forEach((datapointKey) => {
      const fieldValues = transformFieldValues(
        datapointKey,
        datapoints[datapointKey]
      );
      switch (datapointKey) {
        case "trap":
          replaceTrapField(fieldValues);
          break;
        case "dynamics":
          replaceDynamicsField(fieldValues);
          break;
        case "statics":
          replaceStaticsField(fieldValues);
          break;
        case "alarms":
          replaceAlarmsField(fieldValues);
          break;
      }
    });
  }, [
    configData,
    transformFieldValues,
    replaceTrapField,
    replaceDynamicsField,
    replaceStaticsField,
    replaceAlarmsField,
  ]);

  const handleSubmit = () => {
    const { alarms, dynamics, statics, trap, details } = form.getValues();
    const payload = {
      ...configData,
      datapoints: {
        dynamics,
        statics,
        trap,
      },
      alarms: alarms.map((alarm) => ({
        name: alarm.name,
        description: alarm.description,
        email: alarm.email,
        level: alarm.level,
        status: alarm.status,
        properties: {
          attribute_name: alarm.attribute_name,
          type: alarm.type,
          value: alarm.value,
        },
      })),
      active: details[0],
      device_id: details[0].deviceId,
      group: details[0].group,
      mode: {
        ...configData.mode,
        period: details[0].interval,
      },
      ip_address: details[0].ip_address,
      name: details[0].name,
      port: details[0].port,
      credentials: {
        ...configData.credentials,
        read_community: details[0].read_community,
        write_community: details[0].write_community,
      },
    };
    if (state?.type === "update") {
      updateGatewayDevice(payload);
    } else {
      addGatewayDevice(payload);
    }
  };

  const handleCancel = () => {
    navigate(`/gateway/manage-gateway`, {
      state: {
        ...state,
        gatewayId: state?.gatewayId,
        tenantId: state?.tenantId,
      },
    });
  };

  const tabs = useMemo(() => {
    switch (state?.configData?.protocol) {
      case "snmpv2c":
        return [
          "gateway_dynamics",
          "gateway_statics",
          "gateway_trap",
          "gateway_alarms",
        ];
      case "modbustcp":
        return ["gateway_dynamics", "gateway_statics", "gateway_alarms"];
      case "efento":
        return ["gateway_dynamics", "gateway_alarms"];
      default:
        return [];
    }
  }, [state]);

  return (
    <Box sx={{ margin: "24px" }}>
      <BasicBreadcrumbs activePage={"Gateway"} />
      <Header
        connectionType={intl.formatMessage({
          id: configData?.protocol || "snmpv2c",
        })}
        type={intl.formatMessage({
          id:
            state?.type === "create"
              ? "gateway_config_create"
              : "gateway_config_update",
        })}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        intl={intl}
      />
      <Grid container justifyContent={"space-between"} mt="16px">
        <Grid
          item
          xs={3}
          sx={{ backgroundColor: colors.white, borderRadius: "8px" }}
        >
          <Details
            form={form}
            colors={colors}
            intl={intl}
            configData={configData}
          />
        </Grid>
        <Grid
          item
          xs={8.9}
          sx={{
            backgroundColor: colors.white,
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <MenuTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            colors={colors}
            intl={intl}
            tabs={tabs}
          />
          {activeTab === "gateway_dynamics" && (
            <Dynamics
              form={form}
              colors={colors}
              intl={intl}
              dynamics={dynamics}
              append={appendDynamicsField}
              fieldArray={dynamicsFieldArray}
              remove={removeDynamicsField}
            />
          )}
          {activeTab === "gateway_statics" && (
            <Statics
              form={form}
              colors={colors}
              intl={intl}
              statics={statics}
              append={appendStaticsField}
              fieldArray={staticsFieldArray}
              remove={removeStaticsField}
            />
          )}
          {activeTab === "gateway_trap" && (
            <Trap
              form={form}
              colors={colors}
              intl={intl}
              trap={trap}
              append={appendTrapField}
              fieldArray={trapFieldArray}
              remove={removeTrapField}
            />
          )}
          {activeTab === "gateway_alarms" && (
            <Alarm
              form={form}
              colors={colors}
              intl={intl}
              alarms={alarms}
              append={appendAlarmsField}
              fieldArray={alarmsFieldArray}
              remove={removeAlarmsField}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default Connection;
