import { CardContent, Typography, Box } from "@mui/material";
import { LOCALES, BasicCard } from "@datwyler/shared-components";
import { CardDataType } from "../utils/types";

const StatusCard = ({
  name,
  value,
  icon: Icon,
  intl,
  iconBackgroundColor,
  iconColor,
}: CardDataType) => {
  return (
    <BasicCard sx={{ height: "104px", boxShadow: "none", padding: 0 }}>
      <CardContent>
        <Box
          sx={{
            height: "24px",
            width: "24px",
            padding: "8px",
            borderRadius: "20px",
            backgroundColor: iconBackgroundColor,
          }}
        >
          <Icon
            sx={{
              width: 24,
              height: 24,
              color: iconColor,
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "NotoSans-Regular",
              fontSize:
                intl.locale === LOCALES.FRENCH || intl.locale === LOCALES.GERMAN
                  ? "14px"
                  : "16px",
              letterSpacing: "0.02px",
              lineHeight: "24px",
            }}
          >
            {intl.formatMessage({ id: name })}
          </Typography>
          <Typography
            sx={{
              fontFamily: "NotoSans-SemiBold",
              fontSize: "16px",
              fontWeight: "600",
              letterSpacing: "0.02px",
              lineHeight: "17px",
            }}
          >
            {intl.formatMessage({
              id: value,
            })}
          </Typography>
        </Box>
      </CardContent>
    </BasicCard>
  );
};

export default StatusCard;
