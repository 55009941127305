export const ENUM_ON = "ON";
export const ENUM_OFF = "OFF";
export const DEACTIVATED = "DEACTIVATED";
// Environment Device Types
export const DOOR_CONTACT = "DOOR_CONTACT";
export const WATER_LEAK = "WATER_LEAK";
export const SMOKE_DETECTOR = "SMOKE_DETECTOR";
export const TEMP_HUMIDITY = "TEMP_HUMIDITY";

// Device Dialog
export const ENIVIRONMENT_DEVICES = [
  WATER_LEAK,
  SMOKE_DETECTOR,
  TEMP_HUMIDITY,
  DOOR_CONTACT,
];

export const MAXCHAR_NAME = 20;

export const ENVIRONMENT = "Environment";

export const PROTOCOLS = ["snmpv2c", "modbustcp", "efento"];
