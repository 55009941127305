import { getRequiredDateFormat } from "@datwyler/shared-components";
import { ENUM_ON, ENUM_OFF, ENIVIRONMENT_DEVICES } from "./constants";
import { CardDataType, TelemetryType, ColorsType } from "./types";
import { IntlShape } from "react-intl";
import getStyles from "./styles";
import Connection from "../containers/connection";

const getConnectionUptime = (device, signalStrength, intl) => {
  if (!signalStrength) return intl.formatMessage({ id: "not_available" });
  const uptime = getKey(device.telemetry, "uptime");
  if (uptime) {
    const uptimeArr = uptime.split(":");
    const days = uptimeArr[0];
    const hours = uptimeArr[1];
    const min = uptimeArr[2];
    const sec = uptimeArr[3].substring(0, uptimeArr[3].indexOf("."));

    let uptimeString = "";
    if (uptimeArr[0] != "0")
      uptimeString += ` ${days} ${intl.formatMessage({ id: "days" })}`;
    if (uptimeArr[1] != "0")
      uptimeString += ` ${hours} ${intl.formatMessage({ id: "hours" })}`;
    if (uptimeArr[2] != "0")
      uptimeString += ` ${min} ${intl.formatMessage({ id: "minutes" })}`;
    if (uptimeArr[3] != "0")
      uptimeString += ` ${sec} ${intl.formatMessage({ id: "seconds" })}`;

    return uptimeString;
  }

  return intl.formatMessage({ id: "not_available" });
};

export const getTelemetryData = (
  telemetry: TelemetryType[],
  keys: {
    [key: string]: {
      icon: React.FC<{
        sx: Record<string, string | number>;
      }>;
      width: string | number;
      order: number;
    };
  },
  intl: IntlShape,
  colors: ColorsType
): CardDataType[] => {
  const data: CardDataType[] = [];
  telemetry?.forEach((telemetryObj) => {
    if (keys[telemetryObj.name]) {
      data.push(
        formatCardValue(
          {
            name: "gateway_" + telemetryObj.name,
            value: telemetryObj.value,
            ...keys[telemetryObj.name],
          } as CardDataType,
          intl,
          colors
        )
      );
    }
  });
  return data.sort((a, b) => {
    return a.order - b.order;
  });
};

export const formatCardValue = (
  cardData: CardDataType,
  intl: IntlShape,
  colors: ColorsType
): CardDataType => {
  let value = cardData.value;
  let iconColor = "";
  let iconBackgroundColor = "";
  switch (cardData.name) {
    case "gateway_status":
      iconColor = value === ENUM_ON ? colors.green600 : colors.red500;
      iconBackgroundColor = value === ENUM_ON ? colors.green50 : colors.red50;
      value = value === ENUM_ON ? "device_on" : "device_off";
      break;
    case "gateway_modem_operator":
      value = value ? "sim_inserted" : "sim_not_inserted";
      iconBackgroundColor = colors.yellow100;
      break;
    case "gateway_last_connection":
      value = value
        ? (getRequiredDateFormat(
            parseInt(value),
            "DD-MM-YYYY HH:mm:ss"
          ) as string)
        : "not_available";
      iconColor = colors.deviceStatusValue;
      iconBackgroundColor = colors.blue50;
      break;
    case "gateway_device_firmware_version":
      value = value ? value : "not_available";
      iconColor = colors.deviceStatusValue;
      iconBackgroundColor = colors.purple50;
      break;
  }
  cardData.value = intl.formatMessage({ id: value });
  cardData.iconColor = iconColor;
  cardData.iconBackgroundColor = iconBackgroundColor;
  return cardData;
};

export const getFormattedInformation = (device: any = {}, colors) => {
  const { keyStyle, valueStyle, highlightedKey } = getStyles(colors);
  return {
    model: {
      value: getKey(device, "model.name"),
      keyStyle,
      valueStyle: highlightedKey,
    },
    site: { value: getKey(device, "site.name"), keyStyle, valueStyle },
    device_type: {
      value: getKey(device, "model.deviceType"),
      keyStyle,
      valueStyle,
    },
    serial_number: {
      value: getTelemetry(device?.telemetry, "device_serial").value,
      keyStyle,
      valueStyle,
    },
    modem_imei: {
      value: getTelemetry(device?.telemetry, "modem_imei").value,
      keyStyle,
      valueStyle,
    },
    modem_operator: {
      value: getTelemetry(device.telemetry, "modem_operator").value,
      keyStyle,
      valueStyle,
    },
    location: { value: "", keyStyle, valueStyle },
  };
};

export const getGaugeInformation = (device, signalStrength, intl, colors) => {
  const { keyStyle, valueStyle } = getStyles(colors);
  return {
    conn_type: {
      value: signalStrength
        ? getTelemetry(device.telemetry, "device_serial").value
        : intl.formatMessage({ id: "not_available" }),
      keyStyle,
      valueStyle,
    },
    conn_state: {
      value: intl.formatMessage({
        id: signalStrength ? "connected" : "disconnected",
      }),
      keyStyle,
      valueStyle: {
        ...valueStyle,
        color: signalStrength ? colors.datwylerTeal : colors.red500,
      },
    },
    conn_uptime: {
      value: getConnectionUptime(device, signalStrength, intl),
      keyStyle,
      valueStyle,
    },
  };
};

export const getTelemetry = (
  telemetryData: TelemetryType[] = [],
  telemetryKey: string
): any => {
  let value = {};
  telemetryData.forEach((telemetry: TelemetryType) => {
    if (telemetry.name === telemetryKey) {
      value = telemetry;
      return;
    }
  });
  return value;
};

export const getKey = <T>(obj: T, keys: string): any => {
  const keysArray: string[] = keys.split(".");
  let value: any = obj;
  for (const key of keysArray) {
    if (!value || !(key in value)) {
      return null;
    }
    value = value[key];
  }
  return value;
};

export const getChartData = (dataFromDb, data) => {
  if (dataFromDb) {
    dataFromDb.forEach((row) => {
      if (row.name === data.name) {
        data.data.push({
          x: new Date(row.time * 1000),
          y: parseFloat(row.value),
        });
      }
    });
  }
  return data;
};

export const getTickValues = (temperatureData) => {
  const values = [];
  if (temperatureData?.data) {
    const numTicks = 10;
    const max = temperatureData.data.length;
    const intervals = Math.floor(max / numTicks);

    if (intervals < 1) {
      temperatureData.data.forEach((d) => {
        values.push(d?.x);
      });
    } else {
      for (let i = 0; i < max; i = i + intervals) {
        values.push(temperatureData.data[i].x);
      }
    }
  }
  return values;
};

export const getTheme = (colors) => ({
  axis: {
    ticks: {
      line: {
        stroke: colors.chartAxisTick,
      },
      text: {
        fill: colors.chartAxisFont,
      },
    },
    legend: {
      text: {
        fill: colors.chartAxisFont,
      },
    },
  },
  legends: {
    text: {
      fill: colors.chartAxisFont,
    },
  },
});

export const getEnvironmentOptions = () => {
  return ENIVIRONMENT_DEVICES.map((type, index) => {
    return {
      priority: index,
      label: type,
      value: type,
    };
  });
};

export const transformSitesFilter = (sites) => {
  if (sites.length) {
    return ["siteId:" + sites.join("|siteId:")];
  }
  return [];
};

export const getSortOptions = (intl) => {
  return [
    {
      priority: 0,
      label: intl.formatMessage({ id: "name" }),
      value: "name",
    },
    {
      priority: 1,
      label: intl.formatMessage({ id: "status" }),
      value: "status",
    },
  ];
};

export const getSiteOptions = (locations = []) => {
  let allSites = [];
  locations.forEach((location) => {
    if (location.sites) allSites = allSites.concat(location.sites);
  });

  return allSites.map((site, index) => {
    return {
      priority: index,
      label: site.name,
      value: site.id,
    };
  });
};

export const setSelectAll = (rules = [], isChecked) => {
  return rules.map((rule) => {
    rule.isChecked = isChecked;
    return rule;
  });
};

export const setSelectRow = (rules = [], row) => {
  return rules.map((rule) => {
    if (row.id === rule.id) rule.isChecked = !row.isChecked;
    return rule;
  });
};

export const getSelectedRules = (rules = []) => {
  return rules.filter((rule) => !!rule.isChecked);
};

function getAddressByGateway(config: any, gateway: string): string {
  switch (gateway) {
    case "snmpv2c":
      return `${config.ip_address}:${config.port}`;
    case "modbustcp":
      return `${config.ip_address}:${config.port}/${config.unit_id}`;
    case "modbusrtu":
      return `${config.serial_port}:${config.baudrate}/${config.unit_id}`;
    case "efento":
      return `${config.serial_number}`;
    default:
      return "";
  }
}

export const formatDeviceConfigs = (deviceConfigData, intl) => {
  return deviceConfigData.map((deviceConfig) => {
    const formattedData: any = {};
    formattedData.group = deviceConfig?.group;
    formattedData.name = deviceConfig?.name;
    formattedData.status = deviceConfig?.active ? ENUM_ON : ENUM_OFF;
    formattedData.details = [
      {
        name: intl.formatMessage({ id: `${deviceConfig?.protocol}_address` }),
        value:
          getAddressByGateway(deviceConfig, deviceConfig?.protocol) ||
          intl.formatMessage({ id: "gateway_na" }),
      },
      {
        name: intl.formatMessage({ id: "gateway_deviceId" }),
        value:
          deviceConfig?.device_id || intl.formatMessage({ id: "gateway_na" }),
      },
    ];
    formattedData.raw = { ...deviceConfig };
    return formattedData;
  });
};

//
export const populateAlarmsTabValues = (
  values = [
    {
      name: "",
      description: "",
      email: "",
      information: "",
      status: "",
      attribute_name: "",
      operator: "",
      value: "",
    },
  ]
) => {
  return values;
};

export const getConfigDetailValues = (configData) => {
  switch (configData?.protocol) {
    case "snmpv2c":
      return {
        name: configData.name || "",
        deviceId: configData.device_id || "",
        group: configData.group || "",
        ip_address: configData.ip_address || "",
        port: configData.port || "",
        read_community: configData.credentials?.read_community || "",
        write_community: configData.credentials?.write_community || "",
        activated: configData.active ? "true" : "false",
        interval: configData.mode?.period || "",
      };
    case "modbustcp":
      return {
        name: configData.name || "",
        deviceId: configData.device_id || "",
        group: configData.group || "",
        ip_address: configData.ip_address || "",
        port: configData.port || "",
        unit_id: configData.unit_id || "",
        activated: configData.active ? "true" : "false",
        interval: configData.mode?.period || "",
      };
    case "efento": {
      return {
        name: configData.name || "",
        deviceId: configData.device_id || "",
        group: configData.group || "",
        serial_number: configData.serial_number || "",
        activated: configData.active ? "true" : "false",
      };
    }
  }
};
