import { useState, useMemo, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "@datwyler/shared-components";
import Devices from "../components/Devices";
import ConfirmDialog from "../components/ConfirmDialog";
import useGatewayConfiguration from "../hooks/useGatewayConfiguration";
import { formatDeviceConfigs } from "../utils/index";
const GatewayConfiguration = ({ intl, colors, tenantId, gatewayId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isAddConnectionOpen, setIsAddConnectionOpen] = useState(false);
  const [isRemoveDeviceOpen, setIsRemoveDeviceOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState();
  const {
    deviceConfigData,
    deviceConfigDataLoading,
    removeGatewayDevice,
    removeGatewayDeviceData,
    reinitializeGatewayConfig,
    reinitializeGatewayConfigData,
  } = useGatewayConfiguration(tenantId, gatewayId);

  const formattedDeviceConfigData = useMemo(
    () => formatDeviceConfigs(deviceConfigData, intl),
    [deviceConfigData, intl]
  );

  useEffect(() => {
    if (reinitializeGatewayConfigData) {
      enqueueSnackbar(
        intl.formatMessage({ id: "gateway_reinitialize_message" }),
        {
          variant: "success",
        }
      );
    }
  }, [enqueueSnackbar, reinitializeGatewayConfigData, intl]);

  useEffect(() => {
    if (removeGatewayDeviceData) {
      enqueueSnackbar(
        intl.formatMessage({ id: "gateway_device_delete_message" }),
        {
          variant: "success",
        }
      );
    }
  }, [enqueueSnackbar, removeGatewayDeviceData, intl]);

  const handleDeleteClick = (deviceConfigData) => {
    setSelectedDevice(deviceConfigData);
    setIsRemoveDeviceOpen(true);
  };

  const handleConfirmRemoveDevice = () => {
    removeGatewayDevice(selectedDevice);
    setIsRemoveDeviceOpen(false);
  };

  const handleReinitialize = () => {
    reinitializeGatewayConfig();
  };

  const handleSettingClick = (deviceConfigData) => {
    navigate(`/gateway/connection`, {
      state: {
        ...location.state,
        configData: deviceConfigData,
        type: "update",
        gatewayId,
      },
    });
  };

  return (
    <>
      <Devices
        intl={intl}
        colors={colors}
        deviceConfigData={formattedDeviceConfigData}
        isAddConnectionOpen={isAddConnectionOpen}
        setIsAddConnectionOpen={setIsAddConnectionOpen}
        handleSettingClick={handleSettingClick}
        handleDeleteClick={handleDeleteClick}
        handleReinitialize={handleReinitialize}
        deviceConfigDataLoading={deviceConfigDataLoading}
        gatewayId={gatewayId}
        tenantId={tenantId}
      />
      <ConfirmDialog
        isOpen={isRemoveDeviceOpen}
        title={intl.formatMessage({ id: "gateway_delete_device" })}
        bodyTextContent={intl.formatMessage({ id: "confirm_delete" })}
        cancelButtonText={intl.formatMessage({ id: "cancel" })}
        confirmButtonText={intl.formatMessage({ id: "delete" })}
        confirmButtonStyle={{
          backgroundColor: colors.red500,
          ":hover": {
            backgroundColor: colors.red800,
          },
        }}
        handleClose={setIsRemoveDeviceOpen}
        handleConfirm={handleConfirmRemoveDevice}
      />
    </>
  );
};

export default GatewayConfiguration;
