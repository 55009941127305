import React, { useState, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import {
  GraphQLApiProvider,
  SnackBarProvider,
  useLocale,
  TranslationProvider,
  CommonStyles,
  DarkModeProvider,
} from "@datwyler/shared-components";
import Router from "./Router";

export default function Root(props: any) {
  const { getDefaultLocale, useLocaleListener } = useLocale();
  const [locale, setLocale] = useState(getDefaultLocale());
  useLocaleListener(setLocale);
  return (
    <React.StrictMode>
      <BrowserRouter>
        <GraphQLApiProvider uri={props.GRAPHQL_URL}>
          <TranslationProvider locale={locale}>
            <DarkModeProvider>
              <SnackBarProvider>
                <CommonStyles>
                  <Router />
                </CommonStyles>
              </SnackBarProvider>
            </DarkModeProvider>
          </TranslationProvider>
        </GraphQLApiProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
