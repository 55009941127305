import { useState, useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useIntl } from "react-intl";
import { DarkModeContext, isFloat } from "@datwyler/shared-components";

const shapeTelemetries = (values) => {
  const filteredValues = values.filter(
    (data) =>
      data.name !== "last_connection" &&
      data.name !== "status" &&
      data.name.substring(0, 3) !== "set"
  );

  return filteredValues.sort((a, b) => a.seq - b.seq);
};

const DeviceDetailTable = (props) => {
  const { device } = props;
  const [telemetries, setTelemetries] = useState([]);
  const intl = useIntl();
  const { darkMode, colors }: any = useContext(DarkModeContext);

  const headerStyle = {
    height: "48px",
    display: "flex",
    alignItems: "center",
    backgroundColor: darkMode ? colors.dataTableHeaderBg : "unset",
  };

  const colStyle = {
    backgroundColor: colors.deviceDetailTableBg,
    height: "63px",
    display: "flex",
    alignItems: "center",
  };

  const headerFontStyle = {
    color: colors.dataTableHeaderFont,
    fontFamily: "NotoSans-Medium",
    fontSize: "14px",
    fontWeight: "500",
    letterSpacing: "0.02px",
    lineHeight: "20px",
    textTransform: "capitalize",
  };

  const dataFontStyle = {
    color: colors.dataTableFont,
    fontFamily: "NotoSans-Regular",
    fontSize: "14px",
    letterSpacing: "0.02px",
    lineHeight: "20px",
  };

  useEffect(() => {
    if (device?.telemetry) setTelemetries(shapeTelemetries(device?.telemetry));
  }, [device]);

  const getHeader = () => (
    <Grid item xs={6}>
      <Grid container>
        <Grid item xs={7.5} sx={{ ...headerStyle, paddingLeft: "54px" }}>
          <Box sx={{ display: "inline-flex" }}>
            <Typography sx={headerFontStyle}>
              {intl.formatMessage({ id: "value" })}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3} sx={headerStyle}>
          <Box sx={{ display: "inline-flex" }}>
            <Typography sx={headerFontStyle}>
              {intl.formatMessage({ id: "input" })}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1.5} sx={headerStyle}>
          <Box sx={{ display: "inline-flex" }}>
            <Typography sx={headerFontStyle}>-</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );

  const getData = (data) => {
    let value = data.type === "MAP" || data.type === "ARRAY" ? "" : data.value;

    // Check if it's a float and round off to 2 decimal places
    if (!!value && isFloat(value)) {
      value = parseFloat(value).toFixed(2);
    }

    return (
      <Grid item xs={6} key={data.name}>
        <Grid
          container
          sx={{ border: darkMode ? "none" : "1px solid " + colors.blueGray200 }}
        >
          <Grid item xs={7.5} sx={{ ...colStyle, paddingLeft: "54px" }}>
            <Box sx={{ display: "inline-flex" }}>
              <Typography sx={dataFontStyle}>
                {data.name ? intl.formatMessage({ id: data.name }) : ""}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={3} sx={colStyle}>
            <Box sx={{ display: "inline-flex" }}>
              <Typography
                sx={{
                  ...dataFontStyle,
                  color: colors.datwylerTeal,
                  fontFamily: "NotoSans-SemiBold",
                  fontWeight: 600,
                }}
              >
                {!!value && isNaN(value) && !isFloat(value)
                  ? intl.formatMessage({ id: value?.toLowerCase() })
                  : value}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1.5} sx={colStyle}>
            <Box sx={{ display: "inline-flex" }}>
              <Typography
                sx={{
                  ...dataFontStyle,
                  color: darkMode
                    ? colors.dataTableFont
                    : colors.dataTableHeaderFont,
                }}
              >
                {data.unit}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        marginTop: "24px",
        paddingBottom: "24px",
      }}
    >
      {telemetries?.length > 0 && (
        <Grid container>
          {getHeader()}
          {telemetries?.length > 1 ? getHeader() : <Grid item xs={6} />}
          {telemetries?.map((data) => {
            return getData(data);
          })}
          {telemetries?.length % 2 !== 0 &&
            telemetries?.length > 1 &&
            getData({})}
        </Grid>
      )}
    </Box>
  );
};

export default DeviceDetailTable;
