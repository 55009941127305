import { useState, useContext } from "react";
import { useIntl } from "react-intl";
import { Typography, Popover, Box } from "@mui/material";
import {
  BasicButton,
  DarkModeContext,
  FilterIcon,
  BasicSelectWithCheckBox,
} from "@datwyler/shared-components";
import { ColorsType } from "../utils/types";

const Filters = ({ filters, setFilters, siteOptions }) => {
  const intl = useIntl();
  const { colors }: { colors: ColorsType } = useContext(DarkModeContext);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [interimFilters, setInterimFilters] = useState({ ...filters });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeSite = (sites: Array<string>) => {
    setInterimFilters({ ...interimFilters, sites: sites });
  };

  const applyFilters = () => {
    setFilters(interimFilters);
    handleClose();
  };

  const resetFilters = () => {
    setFilters({ sites: [] });
    setInterimFilters({ sites: [] });
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <BasicButton
        variant="outlined"
        onClick={handleClick}
        sx={{ border: "1px solid " + colors.blueGray300 }}
      >
        <FilterIcon fill={colors.iconColor} />
        <Typography
          sx={{
            fontFamily: "NotoSans-Medium",
            fontSize: "14px",
            fontWeight: 500,
            letterSpacing: 0,
            lineHeight: "20px",
            marginLeft: "10px",
          }}
        >
          {intl.formatMessage({ id: "filters" })}
        </Typography>
      </BasicButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          marginTop: "10px",
        }}
        slotProps={{
          paper: {
            sx: {
              boxShadow: "0 2px 8px 0 rgba(16,24,40,0.16)",
              borderRadius: "8px",
              backgroundColor: colors.selectDropDownBg,
            },
          },
        }}
      >
        <Box sx={{ padding: "16px" }}>
          <Box sx={{ width: "230px" }}>
            <BasicSelectWithCheckBox
              onChange={handleChangeSite}
              values={interimFilters.sites}
              label={intl.formatMessage({ id: "sites" })}
              menuItems={siteOptions}
            />
          </Box>
          <Box sx={{ width: "230px", marginTop: "16px", textAlign: "right" }}>
            <BasicButton
              variant={"outlined"}
              onClick={resetFilters}
              sx={{
                padding: "12px 16px",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "NotoSans-Regular",
                  fontSize: "14px",
                  letterSpacing: 0,
                  lineHeight: "20px",
                }}
              >
                {intl.formatMessage({ id: "reset" })}
              </Typography>
            </BasicButton>
            <BasicButton
              variant={"contained"}
              onClick={applyFilters}
              sx={{ marginLeft: "20px", padding: "12px 16px" }}
            >
              {intl.formatMessage({ id: "apply" })}
            </BasicButton>
          </Box>
        </Box>
      </Popover>
    </>
  );
};

export default Filters;
