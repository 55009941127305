import { useState, useEffect, useCallback } from "react";
import { useGatewayDeviceConfigs } from "@datwyler/shared-components";
import dump from "../dump.json";
const useGatewayConfiguration = (tenantId, gatewayId) => {
  const [tenantID, setTenantID] = useState(tenantId);
  const [gatewayID, setGatewayID] = useState(gatewayId);
  const {
    fetchGatewayDeviceConfigs,
    fetchGatewayDeviceConfigsData,
    isFetchGatewayDeviceConfigsLoading,
    updateGatewayDeviceConfig,
    isUpdateGatewayDeviceLoading,
    updateGatewayDeviceConfigData,
    removeGatewayDeviceConfig,
    removeGatewayDeviceConfigData,
    isremoveGatewayDeviceLoading,
    addGatewayDeviceConfig,
    isAddGatewayDeviceConfigLoading,
    addGatewayDeviceConfigData,
    reloadGatewayConfig,
    reloadGatewayConfigData,
    isReloadGatewayConfigLoading,
  } = useGatewayDeviceConfigs();

  useEffect(() => {
    setGatewayID(gatewayId);
  }, [gatewayId]);

  useEffect(() => {
    setTenantID(tenantId);
  }, [tenantId]);

  const getGatewayDeviceConfigs = useCallback(() => {
    fetchGatewayDeviceConfigs({
      variables: {
        tenantId: tenantID,
        gatewayId: gatewayID,
      },
    });
  }, [fetchGatewayDeviceConfigs, tenantID, gatewayID]);

  const updateGatewayDevice = useCallback(
    (deviceConfig) => {
      updateGatewayDeviceConfig({
        variables: {
          input: {
            id: deviceConfig.id,
            deviceConfig,
            tenant: { id: tenantID },
            gateway: { id: gatewayID },
          },
        },
      });
    },
    [updateGatewayDeviceConfig, tenantID, gatewayID]
  );

  const removeGatewayDevice = useCallback(
    (deviceConfig) => {
      removeGatewayDeviceConfig({
        variables: {
          input: {
            id: deviceConfig.id,
            protocol: deviceConfig.protocol,
            tenant: { id: tenantID },
            gateway: { id: gatewayID },
          },
        },
      });
    },
    [tenantID, gatewayID, removeGatewayDeviceConfig]
  );

  const addGatewayDevice = useCallback(
    (deviceConfig) => {
      addGatewayDeviceConfig({
        variables: {
          input: {
            deviceConfig: deviceConfig,
            tenant: { id: tenantID },
            gateway: { id: gatewayID },
          },
        },
      });
    },
    [tenantID, gatewayID, addGatewayDeviceConfig]
  );

  const reinitializeGatewayConfig = useCallback(() => {
    reloadGatewayConfig({
      variables: {
        input: {
          tenant: { id: tenantID },
          gateway: { id: gatewayID },
        },
      },
    });
  }, [tenantID, gatewayID, reloadGatewayConfig]);
  useEffect(() => {
    if (tenantID && gatewayID) {
      getGatewayDeviceConfigs();
    }
  }, [getGatewayDeviceConfigs, tenantID, gatewayID]);

  return {
    deviceConfigData: fetchGatewayDeviceConfigsData?.gatewayDeviceConfigs || [],
    deviceConfigDataLoading: isFetchGatewayDeviceConfigsLoading,
    updateGatewayDevice,
    updateGatewayDeviceLoading: isUpdateGatewayDeviceLoading,
    updateGatewayDeviceData: updateGatewayDeviceConfigData,
    removeGatewayDevice,
    removeGatewayDeviceLoading: isremoveGatewayDeviceLoading,
    removeGatewayDeviceData: removeGatewayDeviceConfigData,
    addGatewayDevice,
    addGatewayDeviceConfigLoading: isAddGatewayDeviceConfigLoading,
    addGatewayDeviceData: addGatewayDeviceConfigData,
    reinitializeGatewayConfig,
    reinitializeGatewayConfigLoading: isReloadGatewayConfigLoading,
    reinitializeGatewayConfigData: reloadGatewayConfigData,
  };
};

export default useGatewayConfiguration;
