import { useContext, useEffect, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { DarkModeContext, TenantIdContext } from "@datwyler/shared-components";
import Header from "../components/Header";
import Tabs from "../components/Tabs";
import { ColorsType } from "../utils/types";
import { getTheme } from "../utils/index";
import GatewayInformation from "./gatewayInformation";
// import GatewayAlarms from "./gatewayAlarms";
import GatewayConfiguration from "./gatewayConfiguration";
import useGatewayConfiguration from "../hooks/useGatewayConfiguration";
const ManageGateway = () => {
  const { state } = useLocation();
  const intl = useIntl();
  const { colors }: { colors: ColorsType } = useContext(DarkModeContext);
  const { tenantId }: any = useContext(TenantIdContext);
  const { gatewayId } = state || {};
  const [deviceData, setDeviceData] = useState();
  const [activeTab, setActiveTab] = useState("gateway_information_tab");
  const { deviceConfigData } = useGatewayConfiguration(tenantId, gatewayId);

  const tabs = useMemo(() => {
    return [
      "gateway_information_tab",
      // "gateway_alarms_tab",
      ...(deviceConfigData.length > 0 ? ["gateway_configuration_tab"] : []),
    ];
  }, [deviceConfigData]);
  // const theme = getTheme(colors);
  return (
    <Box sx={{ paddingTop: "76px", paddingLeft: "24px", paddingRight: "24px" }}>
      <Header
        device={deviceData}
        colors={colors}
        intl={intl}
        activePage={"manage-gateway"}
      />
      <Tabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        colors={colors}
        intl={intl}
        tabs={tabs}
      />
      {activeTab === "gateway_information_tab" && (
        <GatewayInformation
          intl={intl}
          colors={colors}
          // theme={theme}
          tenantId={tenantId}
          gatewayId={gatewayId}
          setDeviceData={setDeviceData}
        />
      )}
      {/* {activeTab === "gateway_alarms_tab" && (
        <GatewayAlarms
          colors={colors}
          tenantId={tenantId}
          gatewayId={gatewayId}
          intl={intl}
        />
      )} */}
      {activeTab === "gateway_configuration_tab" && (
        <GatewayConfiguration
          intl={intl}
          colors={colors}
          tenantId={tenantId}
          gatewayId={gatewayId}
        />
      )}
    </Box>
  );
};

export default ManageGateway;
