import { Stack } from "@mui/material";
import { BasicScreenHeader, BasicButton } from "@datwyler/shared-components";

const Header = ({
  connectionType = "snmpv2c",
  type,
  handleSubmit,
  handleCancel,
  intl,
}) => {
  return (
    <BasicScreenHeader
      title={`${type} ${connectionType} Connection`}
      MoreActions={() => {
        return (
          <Stack spacing={2} direction="row">
            <BasicButton
              variant="outlined"
              color="primary"
              onClick={handleCancel}
            >
              {intl.formatMessage({ id: "gateway_cancel_config_btn" })}
            </BasicButton>
            <BasicButton variant="contained" onClick={handleSubmit}>
              {intl.formatMessage({ id: "gateway_submit_config_btn" })}
            </BasicButton>
          </Stack>
        );
      }}
    />
  );
};

export default Header;
