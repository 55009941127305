const NoDataPage = () => {
  return (
    <div>
      <p>There is no telemetry information on this device.</p>
      <a href="/gateway" style={{ color: "#009FB4", textDecoration: "none" }}>
        Go Back to Previous Page
      </a>
    </div>
  );
};

export default NoDataPage;
