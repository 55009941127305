import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  IconButton,
  Grid,
} from "@mui/material";
import {
  ExpandMoreIcon,
  DeleteIcon,
  BasicButton,
  SaveIcon,
  BasicTextField,
  BasicSelect,
} from "@datwyler/shared-components";

const AccordionUsage = ({
  colors,
  form,
  intl,
  inputFields,
  index,
  type,
  handleDeleteRow,
}) => {
  const createFields = () => {
    return Object.keys(inputFields).map((fieldKey) => {
      const fieldDetails = inputFields[fieldKey];
      switch (fieldDetails.type) {
        case "text":
          return (
            <Grid item key={fieldDetails.name}>
              <BasicTextField
                size="small"
                form={form}
                label={intl.formatMessage({ id: fieldDetails.label })}
                type={"text"}
                formItemLabel={`${type}.${index}.${fieldDetails.name}`}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          );
        case "number":
          return (
            <Grid item key={fieldDetails.name}>
              <BasicTextField
                size="small"
                form={form}
                label={intl.formatMessage({ id: fieldDetails.label })}
                type={"number"}
                formItemLabel={`${type}.${index}.${fieldDetails.name}`}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          );
        case "select":
          return (
            <Grid item xs={2.5} key={fieldDetails.name}>
              <BasicSelect
                size="small"
                isDisableMenuTranslations={true}
                form={form}
                menuItems={(fieldDetails.options || []).map((option) => ({
                  priority: option.priority,
                  label: intl.formatMessage({ id: option.label }),
                  value: option.value,
                }))}
                formItemLabel={`${type}.${index}.${fieldDetails.name}`}
                label={intl.formatMessage({ id: fieldDetails.label })}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          );
      }
    });
  };

  return (
    <Accordion
      defaultExpanded
      sx={{ borderWidth: "2px", border: "1px solid rgba(0, 0, 0, .125)" }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <IconButton onClick={() => handleDeleteRow(index)}>
          <DeleteIcon fill={colors.iconColor} />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          {createFields()}
        </Grid>
      </AccordionDetails>
      {/* <AccordionActions>
        <BasicButton>
          <SaveIcon fill={colors.white} sx={{ paddingRight: "4px" }} />
          Save
        </BasicButton>
      </AccordionActions> */}
    </Accordion>
  );
};

export default AccordionUsage;
