import { useMemo, useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { Stack, Box } from "@mui/material";
import { AddIcon, BasicButton } from "@datwyler/shared-components";
import AccordionUsage from "./Accordion";
import { CONFIG_TABS } from "./config";

const Statics = ({
  form,
  colors,
  intl,
  statics,
  fieldArray,
  append,
  remove,
}: any) => {
  const handleAddRow = () => {
    const defaultValues = {};
    Object.keys(statics).forEach((key) => {
      defaultValues[key] = statics[key].defaultValue;
    });
    append({ ...defaultValues });
  };

  return (
    <Stack direction="column" spacing={2} margin={2}>
      <Box sx={{ textAlign: "right" }}>
        <BasicButton variant="contained" onClick={handleAddRow}>
          <AddIcon fill={colors.white} />
        </BasicButton>
      </Box>
      {fieldArray.map((field, index) => {
        return (
          <AccordionUsage
            key={field.id}
            colors={colors}
            form={form}
            inputFields={statics}
            index={index}
            type="statics"
            handleDeleteRow={remove}
            intl={intl}
          />
        );
      })}
    </Stack>
  );
};

export default Statics;
