import { Grid, Box, CircularProgress } from "@mui/material";
import Cards from "../components/Cards";
// import Information from "../components/Information";
// import SignalGauge from "../components/SignalGauge";
// import Temperature from "../components/Temperature";
// import SignalAreaChart from "../components/SignalChart";
import useGatewayInformation from "../hooks/useGatewayInformation";
import DeviceDetailTable from "../components/DeviceDetailTable";
import NoDataPage from "../components/NoDataPage";
import { useEffect } from "react";

const GatewayInformation = ({
  intl,
  colors,
  // theme,
  tenantId,
  gatewayId,
  setDeviceData,
}) => {
  const {
    deviceData,
    isDeviceDataLoading,
    // telemetryData,
    // isTelemetryDataLoading,
  } = useGatewayInformation(tenantId, gatewayId);

  useEffect(() => {
    setDeviceData(deviceData);
  }, [setDeviceData, deviceData]);
  // const modelId = getKey(deviceData, "model.id");
  // const getSignalStrength = () => {
  //   const status = getKey(deviceData?.telemetry, "status");
  //   const signal = getKey(deviceData?.telemetry, "modem_signal");
  //   if (signal && status === ENUM_ON) {
  //     return parseInt(signal);
  //   }
  //   return null;
  // };

  // const getGaugeData = (tempSignalStrength) => {
  //   if (!tempSignalStrength) {
  //     return [
  //       {
  //         id: "Signal",
  //         data: [
  //           {
  //             x: "signal",
  //             y: -110,
  //           },
  //           {
  //             x: "",
  //             y: 0,
  //           },
  //         ],
  //       },
  //     ];
  //   }

  //   return [
  //     {
  //       id: "Signal",
  //       data: [
  //         {
  //           x: "signal",
  //           y: tempSignalStrength,
  //         },
  //         {
  //           x: "",
  //           y: -(110 + tempSignalStrength),
  //         },
  //       ],
  //     },
  //   ];
  // };
  // const informationMap = getFormattedInformation(deviceData, colors);

  // const signalStrength = getSignalStrength();
  // const gaugeData = getGaugeData(signalStrength);
  // const gaugeInformation = getGaugeInformation(
  //   deviceData,
  //   signalStrength,
  //   intl,
  //   colors
  // );

  // const temperatureData = getChartData(telemetryData, {
  // id: deviceData?.name,
  // color: "#009fb4",
  // data: [],
  // name: "modem_temperature",
  // });
  // const temperatureTickVaues = getTickValues(temperatureData);

  // const signalData = getChartData(telemetryData, {
  //   id: deviceData?.name,
  //   color: "hsl(216, 70%, 50%)",
  //   data: [],
  //   name: "modem_signal",
  // });
  // const signalTickVaues = getTickValues(signalData);
  return (
    <>
      {isDeviceDataLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "250px",
            color: colors.datwylerTeal,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      ) : (
        <>
          {(deviceData?.telemetry || []).length <= 0 && <NoDataPage />}
          <Cards device={deviceData} intl={intl} colors={colors} />
          <Grid container sx={{ marginTop: 2, marginBottom: 2 }} spacing={2}>
            <DeviceDetailTable device={deviceData} />
            {/* <Grid item xs={12} lg={6}>
              <Information
                title="device_information"
                intl={intl}
                colors={colors}
                information={informationMap}
                image={modelId}
              />
            </Grid> */}
            {/* <Grid item xs={12} lg={6}>
              <SignalGauge
                title="connection"
                intl={intl}
                colors={colors}
                signalLabel="signal_strength_0"
                gaugeData={gaugeData}
                information={gaugeInformation}
              />
            </Grid>
            <Grid item xs={12}>
              <Temperature
                temperatureData={temperatureData}
                tickValues={temperatureTickVaues}
                isTelemetryDataLoading={isTelemetryDataLoading}
                theme={theme}
                colors={colors}
                intl={intl}
              />
            </Grid>
            <Grid item xs={12}>
              <SignalAreaChart
                signalData={signalData}
                tickValues={signalTickVaues}
                isTelemetryDataLoading={isTelemetryDataLoading}
                theme={theme}
                colors={colors}
                intl={intl}
              />
            </Grid> */}
          </Grid>
        </>
      )}
    </>
  );
};

export default GatewayInformation;
