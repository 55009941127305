import { useState, useEffect } from "react";
import { Stack, Box } from "@mui/material";
import { AddIcon, BasicButton } from "@datwyler/shared-components";
import AccordionUsage from "./Accordion";

const Alarm = ({
  form,
  colors,
  intl,
  alarms,
  append,
  fieldArray,
  remove,
}: any) => {
  const [localAlarmFields, setLocalAlarmFields] = useState({ ...alarms });

  useEffect(() => {
    const dynamics = form.getValues("dynamics");
    const alarmAttributeOptions = dynamics.map((dynamic, index) => ({
      priority: index,
      label: dynamic.key,
      value: dynamic.key,
    }));
    const alarmsFields = { ...localAlarmFields };
    alarmsFields.attribute_name.options = alarmAttributeOptions;
    setLocalAlarmFields(alarmsFields);
  }, [form]);

  const handleAddRow = () => {
    const defaultValues = {};
    Object.keys(alarms).forEach((key) => {
      defaultValues[key] = alarms[key].defaultValue;
    });
    append({ ...defaultValues });
  };

  return (
    <Stack direction="column" spacing={2} margin={2}>
      <Box sx={{ textAlign: "right" }}>
        <BasicButton variant="contained" onClick={handleAddRow}>
          <AddIcon fill={colors.white} />
        </BasicButton>
      </Box>
      {fieldArray.map((field, index) => {
        return (
          <AccordionUsage
            key={field.id}
            colors={colors}
            form={form}
            inputFields={localAlarmFields}
            index={index}
            type="alarms"
            handleDeleteRow={remove}
            intl={intl}
          />
        );
      })}
    </Stack>
  );
};

export default Alarm;
